
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "LNotFound",
  setup() {
    useHead({ title: "Pagina não encontrada | obmed" });
  },
});
